.welcome-container {
    width:80%;
    height: auto;
    margin: auto;
    margin-bottom: 5rem;
    position: relative;
}

.welcome-firstCol {
    text-align: left;
}
.welcome-img {
    padding:  0;
    text-align: center;
    width: 100%;
    height: auto;
}
.welcome-img:hover {
    transform: scale(1.5); 
    transition: transform 500ms ease-in-out;

}

@media(min-width:768px) {
    .welcome-img {
        padding: 0;
        width:70%;
    }
}
