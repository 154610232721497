.slider-container  {
  background-color: #e2e4e8;
    margin: 0 auto 0 auto;
    width: 100%;
    height: auto;
    padding:2rem;
}
.img-y {
  width: 100%;
  padding: 2px;
  height: auto; 
}

