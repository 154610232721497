.servicesIcons-container {
    width:80%;
    height: auto;
    background-color: none;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.icons {
    text-align: left;
    margin: auto;
    height: auto;
    padding-bottom:1rem;
}
@media(min-width: 425px) {
    .icons {  
        margin-bottom: 2rem; 
    }
}
.icon-1 {
width:auto;
border-right: 1px solid grey;
}
.icon-2 {
width:auto;
border-right: 1px solid grey;
}
.icon-3 {
width:auto;
border-right: 1px solid grey;
}