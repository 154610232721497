.footer-container {
    width:100%;
    height: auto;
    color: #f5f3dc;
    margin: auto;
    background-color: #515151;
    font-family: 'Montserrat', sans-serif;
  position: relative;
}

  .img_logo {
    width:150px;
    height: auto;
   
  }
.contacts-container {
  height: 70vh;
  background-image: url(../images/fc1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color:#f5f3dc;
  padding-top: 2rem;
  font-family: 'Montserrat', sans-serif;
  margin: auto;
}
.slogan {
  color: rgb(227, 209, 16);
  font-weight: 600;
 
}
.phone-color{
  color: rgb(227, 209, 16);
}
.nextek {
  color:rgb(4, 21, 48);
}
.nextek:hover {
  color:pink;
}
a {
  text-decoration: none;
}
.facebook {
  color:antiquewhite;
  font-size: 24px;
  margin-right: 1rem;
}