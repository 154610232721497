.grid-container{
    width: 100%;
    height: auto;
}
.testimonial-grid {
    display: grid;
    gap: 1.5rem;
    grid-auto-columns: 1fr;
    grid-template-areas:
     'one'
     'two'
     'three'
     'four'
     'five';
  
    padding-block: 2rem;
    width: min(90%);
    height: 100%;
    margin-inline: auto;
  }
  
  .testimonial {
    font-size: var(--fs-400);
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 2.5rem 3.75rem 3rem -3rem hsl(var(--clr-secondary-400) / 0.25);
  }
  
  .testimonial:nth-child(1) {
    grid-area: one;
  }
  .testimonial:nth-child(2) {
    grid-area: two;
  }
  .testimonial:nth-child(3) {
    grid-area: three;
  }
  .testimonial:nth-child(4) {
    grid-area: four;
  }
  .testimonial:nth-child(5) {
    grid-area: five;
  }
  
  @media screen and (min-width: 33em) {
    .testimonial-grid {
        grid-template-areas:
        "one one"
        "two three"
        "five five"
        "four four";
    }
  }
  
  @media screen and (min-width: 38em) {
    .testimonial-grid {
      grid-template-areas:
       'one one'
       'two five'
       'three five'
       'four four';
    }
  }
  
  @media screen and (min-width: 54em) {
    .testimonial-grid {
        grid-template-areas:
        "one one two"
        "five five five"
        "three four four";
    }
  }
  
  @media screen and (min-width: 75em) {
    .testimonial-grid {
        grid-template-areas:
        "one one two five"
        "three four four five";
    }
  }
  
  
  .testimonial.quote {
     background-image: url("../images/salon.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    /* height: 400px; */
  }
  .second {
    background-image: url("../images/p1.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    height: 400px;
}
.third {
    background-image: url("../images/p2.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    height: 400px;
}
.four {
    background-image: url("../images/3.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
  
}
.five {
    background-image: url("../images/p3.jpg");
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;

}
.six {
  background-image: url("../images/friend.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  height: 400px;

}
.seven {
  background-image: url("../images/diplom.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  height: 400px;

}
.eight {
  background-image: url("../images/max1.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  height: 400px;

}
.nine {
  background-image: url("../images/1.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  height: 400px;

}
  
  .testimonial img {
    width: 1.75rem;
    aspect-ratio: 1;
    border-radius: 50%;
  }
  
  .testimonial .name {
    font-size: var(--fs-400);
    font-weight: var(--fw-400);
    line-height: 1;
  }
  
  .testimonial .position {
    font-size: var(--fs-300);
    opacity: 0.5;
  }
  
  .testimonial > p:first-of-type {
    font-size: var(--fs-500);
    line-height: 1.2;
  }
  
  .testimonial > p:last-of-type {
    opacity: 0.7;
  }
  /* .zoom:hover{
    transform:  scaleY(100%);
  } */

  @media (max-width:1024px) {
    .testimonial.quote, .four, .five {
      height:400px;
    }
  }