.blogQwestions-container {
    width:80%;
   margin: auto;
   text-align: left;
   font-family: 'Montserrat', sans-serif;
}
 ul{
    list-style-type: square;
 }
.b-title-main {
    text-decoration: underline;
    margin-bottom: 3rem;
    font-weight: 600;
}
.title-blog {
    font-weight: 600;
}
.video-blog {
    margin: auto;
    width:100%;
}
.video1-blog {
    margin: auto;
    width:50%;
}
.youtube {
   
    height: 400px;
}