
  .navbar {
    width:100%;
    background-color: rgba(47, 46, 41, 0.5);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 1;
    
  }
  
  .nav-container {
    background-color: rgba(47, 46, 41, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    /* max-width: 1500px; */
  }
  
  .nav-logo {
    color: #ffffff;
    align-items: center;
    margin-left: 30px;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
  }
  .img_logo_nav {
    height: 50px;
    position: absolute;
    top:20%;
    left:5%;
  }
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
   align-items: center;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .fa-code {
    margin-left: 1rem;
  }
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
    width: 100%;
    background: #ececea;
  }
  
  .nav-item .active {
    color: #e4e3e2;
    text-decoration: none;
    /* border: 1px solid #dfdedb; */
  }
  
  .nav-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background-color: rgba(47, 46, 41, 0.8);
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: #ebe9e4;
      border: none;
    }
    .nav-links {
      padding: 1rem;
      width: 100%;
      display: table;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #f3f2ed;
    }
  }
 