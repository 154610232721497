@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.first-component-container {
   
    width:100%;
    height: auto;
    background-image: url(../images/fc3.png); 
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    position: relative;
   margin: auto;
   
   
}
.title-fc {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    padding: 1rem;
    font-weight: 600;
    color:rgb(254, 254, 250);
} 
.h3-color {
  color: rgb(227, 209, 16);
  font-weight: 600;
  font-size: 22px;
  overflow-wrap: break-word;
  text-shadow: 1px 1px rgb(0, 0, 0);
}
.h3-color:hover {
  
  transform: scale(1.2);
  transition: transform 500ms ease-in-out;
}
@media (min-width:768px) {
  .title-fc {
    font-size: 16px;
} 
.h3-color {
  font-size: 24px;
}
}