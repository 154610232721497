@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.emptyHome-container {
    width:100%;
    height:40vh;
     background-image: url(../images/blue.jpg);
    text-align: center;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    color:aliceblue;
}
/* #video {
    position: fixed;
    z-index: -1;
    width:100%;
} */
