.service2-container {
    width:80%;
    margin: auto;
}

.icons-2 {
    text-align: left;
    margin: auto;
    height: auto;
    padding-bottom:1rem;
}
@media(min-width: 425px) {
    .icons {  
        margin-bottom: 2rem; 
    }
}
